<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers='headers'
        :items='appts'
        :footer-props='footerProps'
        item-key='Id'
        :loading='loading'
        class='elevation-0'
        :sort-by.sync='pageSortBy'
        sort-desc.sync='true'
      >
        <template v-slot:item.AppointmentDate="{ item }">
          {{ item.AppointmentDate | FormatDateIST }}
        </template>
        <template v-slot:item.AppointmentSlot="{ item }">
          {{ item.AppointmentSlot | IntToSlot }}
        </template>
        <template v-slot:item.Status="{ item }">
          {{ item.Status | IntToApptStatus }}
        </template>
        <template v-slot:item.actions="{ item }">
          <button
              class='linkBtn'
              type='button'
              @click='viewAppointment(item)'
          >
            View
          </button>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import WebH from '../../api/web';
export default {
  computed: {
    appts: {
      get() {
        return this.$store.getters['ApptsStore/Appointments'];
      }
    },
    loading: {
      get() {
        return this.$store.getters['ApptsStore/Loading'];
      },
      set(value) {
        this.$store.commit('ApptsStore/setLoading', value);
      }
    },
    // totalItems: {
    //   get() {
    //     //return 200;
    //     return Math.ceil(this.$store.getters['ApptsStore/Total'] / 50);
    //   }
    // },
    search: {
      get() {
        return this.$store.getters['ApptsStore/Search'];
      }
    }
  },
  data() {
    return {
      footerProps: {itemsPerPageOptions: [25, 50]},
      pageSortBy: 'AppointmentDate',
      headers: [
        {
          text: 'Patient Id',
          value: 'PatientId',
          sortable: true,
          width: '10%'
        },
        { text: 'Name', value: 'Name', sortable: true, width: '20%' },
        { text: 'Mobile', value: 'Mobile', sortable: true, width: '10%' },
        {
          text: 'Appointment Date',
          value: 'AppointmentDate',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Appointment Slot',
          value: 'AppointmentSlot',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Status',
          value: 'Status',
          sortable: true,
          width: '10%'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '15%',
          align: 'center'
        }
      ]
    };
  },
  watch: {
    pagination() {
      if (!this.search.from) return;
      this.api_get(this.pagination.page);
    }
  },
  methods: {
    viewAppointment(item) {
      this.$router.push({
        name: 'appointment',
        params: { appointment: item }
      });
    },
    async api_get(page) {
      this.loading = true;
      this.$store.commit('ApptsStore/setAppointments', []);
      let url = '/apptmnts/sget?';
      url += 'pid=' + this.search.pid;
      url += '&mob=' + this.search.mob;
      url += '&st=' + this.search.st;
      url += '&from=' + this.search.from;
      url += '&to=' + this.search.to;
      url += '&pn=' + (page - 1);
      const res = await WebH.Get(url, 'appointments');
      if (res.Success) {
        this.$store.commit('ApptsStore/setAppointments', res.Data);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
