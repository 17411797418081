<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
  <v-layout row wrap>
    <div class='filterStyle'>
      <v-text-field label='Patient Id:' v-model='pid'>
        <template slot='prepend'>
          <v-icon class='filterIcon grey--text text--darken-1'
            >fa fa-filter</v-icon
          >
        </template>
      </v-text-field>
    </div>
    <div class='filterStyle'>
      <v-text-field label='Mobile:' v-model='mob'>
        <template slot='prepend'>
          <v-icon class='filterIcon grey--text text--darken-1'
            >fa fa-filter</v-icon
          >
        </template>
      </v-text-field>
    </div>
    <div class='filterStyle'>
      <v-autocomplete
        label='Status'
        v-model='st'
        :items='searchStatus'
        item-text='Name'
        item-value='Id'
      >
        <template slot='prepend'>
          <v-icon class='filterIcon grey--text text--darken-1'
            >fa fa-filter</v-icon
          >
        </template>
      </v-autocomplete>
    </div>
    <div class='filterStyle'>
      <v-autocomplete
        label='Date:'
        v-model='dateType'
        :items='dateOptions'
        item-text='Name'
        item-value='Id'
      >
        <template slot='prepend'>
          <v-icon class='filterIcon grey--text text--darken-1'
            >fa fa-filter</v-icon
          >
        </template>
      </v-autocomplete>
    </div>
    <div class='filterStyle'>
      <v-dialog v-model='fromShow' width='290px'>
        <template v-slot:activator='{ on }'>
          <v-text-field
            :disabled='dateType !== 3'
            v-model='from'
            label='From Date:'
            readonly
            v-on='on'
          >
            <template slot='prepend'>
              <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-filter</v-icon
              >
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model='from' scrollable @input='fromShow = false'>
        </v-date-picker>
      </v-dialog>
    </div>
    <div class='filterStyle'>
      <v-dialog v-model='toShow' lazy full-width width='290px'>
        <template v-slot:activator='{ on }'>
          <v-text-field
            :disabled='dateType !== 3'
            v-model='to'
            label='To Date:'
            readonly
            v-on='on'
          >
            <template slot='prepend'>
              <v-icon class='filterIcon grey--text text--darken-1'
                >fa fa-filter</v-icon
              >
            </template>
          </v-text-field>
        </template>
        <v-date-picker v-model='to' scrollable @input='toShow = false'>
        </v-date-picker>
      </v-dialog>
    </div>
    <v-flex>
      <v-btn color='primary' :disabled='loading' @click='api_get'>
        <v-icon class='iconText'>fa fa-search</v-icon>
        Search
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import WebH from '../../api/web';

export default {
  data() {
    return {
      searchStatus: [
        { Id: 0, Name: 'On going' },
        { Id: 1, Name: 'Completed' },
        { Id: 2, Name: 'Cancelled' }
      ],
      dateType: 0,
      dateOptions: [
        { Id: 0, Name: 'Today' },
        { Id: 1, Name: 'Next 7 days' },
        { Id: 2, Name: 'Next 30 days' },
        { Id: 3, Name: 'Custom' }
      ],
      fromShow: false,
      toShow: false
    };
  },
  computed: {
    pid: {
      get() {
        return this.$store.getters['ApptsStore/Search'].pid;
      },
      set(value) {
        return this.$store.commit('ApptsStore/setPid', value);
      }
    },
    mob: {
      get() {
        return this.$store.getters['ApptsStore/Search'].mob;
      },
      set(value) {
        return this.$store.commit('ApptsStore/setMob', value);
      }
    },
    from: {
      get() {
        return this.$store.getters['ApptsStore/Search'].from;
      },
      set(value) {
        return this.$store.commit('ApptsStore/setFrom', value);
      }
    },
    to: {
      get() {
        return this.$store.getters['ApptsStore/Search'].to;
      },
      set(value) {
        return this.$store.commit('ApptsStore/setTo', value);
      }
    },
    st: {
      get() {
        return this.$store.getters['ApptsStore/Search'].st;
      },
      set(value) {
        return this.$store.commit('ApptsStore/setSt', value);
      }
    },
    loading: {
      get() {
        return this.$store.getters['ApptsStore/Loading'];
      },
      set(value) {
        return this.$store.commit('ApptsStore/setLoading', value);
      }
    }
  },
  methods: {
    addDays(days) {
      let currentDate = new Date();
      if (days === 0) return currentDate;
      currentDate.setDate(currentDate.getDate() + days);
      return currentDate;
    },
    setFromTo(from, to) {
      this.from = this.$options.filters.FormatDateISO(from);
      this.to = this.$options.filters.FormatDateISO(to);
    },
    assignDates() {
      const today = this.addDays(0);
      switch (this.dateType) {
        case 0:
          this.setFromTo(today, today);
          break;
        case 1:
          this.setFromTo(today, this.addDays(7));
          break;
        case 2:
          this.setFromTo(today, this.addDays(30));
      }
    },
    async api_get() {
      this.loading = true;
      this.$store.commit('ApptsStore/setAppointments', []);
      let url = '/apptmnts/search?';
      url += 'pid=' + this.pid;
      url += '&mob=' + this.mob;
      url += '&st=' + this.st;
      this.assignDates();
      url += '&from=' + this.from;
      url += '&to=' + this.to;
      const res = await WebH.Get(url, 'appointments');
      if (res.Success) {
        this.$store.commit('ApptsStore/setTotal', res.Data.Total);
        this.$store.commit('ApptsStore/setAppointments', res.Data.Appointments);
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
