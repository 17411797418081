<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex sm6>
        <div class='headline green--text text--darken-4 font-weight-medium'>
          Appointments
        </div>
      </v-flex>
      <!--      <v-flex xs12 sm12 lg12>-->
      <!--        <v-btn color='primary'>-->
      <!--          <v-icon class='iconText'>fa fa-plus</v-icon>-->
      <!--          New Appointment-->
      <!--        </v-btn>-->
      <!--      </v-flex>-->
      <v-flex xs12 sm12 lg12>
        <SearchComp></SearchComp>
      </v-flex>
      <v-flex xs12 sm12 lg12>
        <ApptsResult></ApptsResult>
      </v-flex>
      <!--      <v-flex xs12 sm12 lg12>-->
      <!--        <TotalComp></TotalComp>-->
      <!--      </v-flex>-->
    </v-layout>
  </v-container>
</template>

<script>
import SearchComp from '../../components/appointment/SearchFilters';
import ApptsResult from '../../components/appointment/ApptsDisplay';
//import TotalComp from '../../components/appointment/SearchResult';
export default {
  components: {
    SearchComp,
    ApptsResult
    //TotalComp
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
